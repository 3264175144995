<template>
  <v-container
    id="usagers"
    fluid
    tag="section"
    data-cy="pageGestionKiosques"
  >
    <base-material-card
      icon="mdi-account-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ $t('kiosques') }}
        </div>
      </template>

      <v-text-field
        v-model="mots"
        append-icon="mdi-magnify"
        class="ml-auto"
        :label="$t('rechercher')"
        hide-details
        single-line
        style="max-width: 250px;"
      />

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="listeKiosques"
        :server-items-length="nKiosques"
        :options.sync="options"
        :loading="loading"
        data-cy="dtKiosques"
        :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 primary"
            min-width="0"
            small
            @click="onEditer(item)"
          >
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onEffacer(item)"
          >
            <v-icon
              small
            >
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        v-if="estAdmin"
        class="primary"
        data-cy="btnCreerKiosque"
        @click="onAjouter()"
      >
        {{ $t('gestion_des_kiosques.creer_nouveau_kiosque.nouveau_kiosque') }}
      </v-btn>
    </base-material-card>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {

    data: () => ({
      mots: '',
      loading: true,
      listeKiosques: [],
      nKiosques: 0,
      options: {},
      headers: [
        { text: i18n.t('nom_commun'), value: 'nom' },
        { text: i18n.t('debut'), value: 'dateDebut' },
        { text: i18n.t('fin'), value: 'dateFin' },
        { text: i18n.t('conferences'), value: 'conference' },
        { text: i18n.t('kiosque'), value: 'kiosque' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
      estAdmin: false,
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
      mots: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    mounted () {
      this.getData()
      this.estAdmin = this.$store.state.userInfo.roles === 'admin'
    },
    methods: {
      getData () {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}&mots=${this.mots}`
        restApiService
          .get(`/api/compte-clients/${this.$store.state.compteClient.id}/kiosques?${arg}`)
          .then((result) => {
            this.listeKiosques = result.data
            this.nKiosques = parseInt(result.headers['x-total-count'])
            this.loading = false
          })
          .catch((erreur) => alert(erreur))
      },
      onEditer (usager) {
        this.$router.push({ path: '/gestion/editionKiosque/' + usager.id })
      },
      async onAjouter () {
        const kiosque = {
          compteClient: {
            id: this.$store.state.compteClient.id,
          },
          nom: '',
        }
        const reponse = await this.$dialog.prompt({
          title: i18n.t('gestion_des_kiosques.creer_nouveau_kiosque.nouveau_kiosque'),
          text: i18n.t('gestion_des_kiosques.creer_nouveau_kiosque.saisir_nom_du_kiosque'),
          rules: [
            v => !!v || i18n.t('champ_requis'),
          ],
          textField: {
            type: 'text',
            id: 'nouveauKiosqueNomlInput',
          },
          actions: {
            false: i18n.t('annuler'),
            true: {
              color: 'red',
              text: i18n.t('confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  kiosque.nom = document.getElementById('nouveauKiosqueNomlInput').value
                  if (kiosque.nom) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        }).catch(() => {
          console.log('Prompt dismissed')
        })

        if (reponse) {
          restApiService
            .post('/api/kiosques', kiosque)
            .then((result) => {
              this.$router.push({ path: `/gestion/editionKiosque/${result.data.id}` + '?nouveau=true' })
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onEffacer (kiosque) {
        this.$dialog.confirm({
          text: this.$t('gestion_des_kiosques.edition_kiosque.confirmation_suppression_kiosque'),
          title: this.$t('attention'),
        }).then((r) => {
          if (r) {
            restApiService
              .delete('/api/kiosques/' + kiosque.id)
              .then((result) => {
                this.getData()
              })
              .catch((erreur) => alert(erreur))
          }
        })
      },

    },
  }
</script>
